import React, { Component } from "react";
import SirayaImg from "../../assets/graphics/siraya/sirayan.jpg";
import KusundaImg from "../../assets/graphics/kusunda/kusunda_hero-min.png";
import nowHereLogo from "../../assets/images/NowHereLogo_dark.png";
import PokePokeLogo from "../../assets/images/PokePokeLogo_dark.png";

class About extends Component {
  render() {
    return (
      <div className="popup">
        <div className="popup_wrapper">
          <div className="popup_inner">
            <h1>About</h1>
            {this.props.episode === "siraya" ? (
              <>
                <div className="about-title">
                  <h2>Episode 1: Diving into Siraya</h2>
                  <img
                    src={PokePokeLogo}
                    className="about-logo"
                    alt="pokepoke-logo"
                  />
                </div>
                <img src={SirayaImg} className="about-img" alt="sirayan" />
                <p>
                  Diving into Siraya is an abstract journey about the indigenous
                  Sirayan language in Taiwan that has been undergoing attempts
                  at revitalisation. Travel along a visualised road of language,
                  experience the journey by listening and watching, and trigger
                  interactions with your voice.
                </p>
                <p>
                  Language learning is encouraged through an experimental
                  immersive experience that is more comparable to early
                  childhood learning than to annotated, classroom methods.
                </p>
                <p>
                  By listening, watching and occasionally interacting, you move
                  forward along a visualised road. Speaking Sirayan words and
                  phrases allows you to weave between scenes along the Sirayan
                  soundwave. Certain characters, captured volumetrically, are
                  presented in vivid particles. Audio recordings of different
                  languages float in the space, with soundwaves fluctuating
                  according to the language of the scene the audience is
                  witnessing.
                </p>
                <p>
                  The Sirayan language is spoken by the Taiwanese plains
                  indigenous people of the Siraya Tribe. During 1629-1662 AD,
                  Dutch missionaries romanised the Sirayan language in an
                  attempt to christianise the population, and bring about
                  far-reaching structural change in their communities. In the
                  19th century, due to the immigration of the Hans from China,
                  the culture of the Siraya people had gradually been Sinicized.
                  Their language and traditions had been lost. In recent years,
                  there has been a movement of the rectification of aboriginal
                  names and terms. After two decades of efforts of
                  revitalisation by the Tainan City Government Ethnic Affairs
                  Commission, the Sirayan language has finally been in use
                  again. However, it has still been listed as extinct by UNESCO,
                  until now. The case of the Sirayan language is rare in
                  comparison to other extinct languages worldwide. It has
                  therefore become an inspiring and intuitive example for this
                  movement.
                </p>
              </>
            ) : this.props.episode === "kusunda" ? (
              <>
                <div className="about-title">
                  <h2>Episode 2: KUSUNDA</h2>
                  <img
                    src={nowHereLogo}
                    className="about-logo"
                    alt="nowhere-logo"
                  />
                </div>
                <img
                  src={KusundaImg}
                  className="about-img"
                  style={{ filter: "grayscale(100%)" }}
                  alt="kusunda"
                />
                <p>
                  KUSUNDA is an interactive virtual reality (VR) experience
                  about the sleeping Kusunda language in western Nepal.
                  Co-created by Kusunda shaman Lil Bahadur and his granddaughter
                  Hima, this intergenerational story invites you to support the
                  challenging task of revitalising a dormant indigenous
                  language.
                </p>
                <p>
                  Every two weeks a language falls asleep. Most languages at
                  threat belong to indigenous communities such as Kusunda in
                  Nepal. The Kusunda language has been categorised by linguists
                  as a language isolate, meaning it’s unrelated to any other
                  language family of the world. Due to their nomadic way of
                  life, the Kusunda call themselves “Kings of the Forest.”
                  Currently there are only about 150 people in Nepal who
                  identify as Kusunda.
                </p>
                <p>
                  Lil Bahadur lost his mother tongue when he gave up his hunter
                  gatherer lifestyle to settle in the village. “I spoke the
                  Kusunda language until I was eighteen years old. After [my
                  parents] died the language slowly faded away. I forgot Kusunda
                  when I was separated from my group and had to travel alone.
                  Later I met other people and started speaking the Nepali Khas
                  language,” he says with a sense of resignation.
                </p>
                <p>
                  Lil’s granddaughter Hima, a bright teenager detached from her
                  grandfather’s hunter-gatherer identity, represents a different
                  Kusunda identity and is taking on the challenge to revive her
                  mother tongue. “If the Kusunda language disappears then the
                  existence of the Kusunda people in Nepal will also fade away.
                  We’ll lose our identity. That’s why I want to save our
                  language.”
                </p>
                {/* <div className="about-title">
                  <h2>Credits</h2>
                  <p>A NowHere Media Production</p>
                  <p>
                    In collaboration with: Gyani Maiya Kusunda Hima Kusunda Lil
                    Bahadur Kusunda
                  </p>
                  <p>
                    Directors and producers: Felix Gaedtke Gayatri Parameswaran
                  </p>
                  <p>------ Executive Producer: René Pinnell</p>
                  <p>Co-producers (INVR): Sönke Kirchhof Philipp Wenning</p>
                  <p>Co-producers (FASAD): Emma Creed Aliki Tsakoumi</p>
                  <p>Co-producers (Poké Poké): Kuan-Yuan Lai</p>
                  <p>Associate Producer: Mia von Kolpakow</p>
                  <p>
                    Art Director, Design & Animation: Moritz Mayerhofer
                    Technical Director & Animation Assistant: Henning Schild
                    Lead Developer: Tobias Wehrum Editor: Mia von Kolpakow
                  </p>
                  <p>
                    Volumetric Video Post-Production: INVR.SPACE Executive
                    Producer: Sönke Kirchhof
                  </p>
                  <p>
                    Producer and Supervisor: Philipp Wenning Post-production:
                    Jessica Zippel Depth Artist: Sara Montenegro Volumetric
                    Capture and Ambisonic Sound Equipment: 360Camrent by
                    INVR.SPACE Rental Supervisor: Christopher Kern Pipeline
                    Test: Adrian Staude, Jens Burgdorf
                  </p>
                  <p>Additional Consultant and Colourist: Evgeny Kalachikhin</p>
                  <p>
                    Photogrammetry Post-processing: realities.io Azad Balabanian
                    Daniel Sproll
                  </p>
                  <p>
                    Motion capture: Immersive Arts Space, Zürcher Hochschule der
                    Künste (ZHdK) with the support of the Ernst Göhner
                    Foundation for the Filmmaker Residency. Head of Immersive
                    Arts Space: Prof. Christian Iseli Subject Area Film, head:
                    Sabine Boss Technical Lead: Tobias Baumann Production Lead:
                    Kristina Jungic Acting Coach and Production Assistance:
                    Corinne Soland Lead Actor: Yan Balistoy Additional Actors:
                    Offir Limacher, Johannes Voges, Corinne Soland, Gayatri
                    Parameswaran, Oliver Sahli, Ferhat Türkoğlu, Kristina Jungic
                    Additional photogrammetry processing: Valentin Huber Witness
                    Camera Operator: Paolo Rüegg
                  </p>
                  <p>
                    Sound and Implementation: We Are Machine Media Technical
                    Sound Design: David Segal, Joakim Johansson Sound Design &
                    Org. Music: Mads Michelsen Hima’s Song Composition: Hema
                    Kusunda & Mads Michelsen Hima’s Room Song Composer: Rajan
                    Shreshta Voice-over Recording Gothenburg: Chimney Sound
                    Technician: Andreas Mellkvist Additional Casting: Archana
                    Khanna English Cast: Helen Silvander as Hima Robert Lyons as
                    Lil Bahadur Swedish Cast: Helen Silvander as Hima Simon
                    Settergren as Lil Bahadur Swedish Narrator: Ri Versteegh
                    English Narrator: Gayatri Parameswaran Nepali Narrator:
                    Sudeep Bhaju Production Consigliere: Jesper Kurlandsky
                  </p>
                  <p>
                    Project Website: Tom Lutherborrow AI Speech Recognition:
                    Carol Han Valerio Velardo Story Consultant: Pola Weiß
                    Additional Producer: Max Kerkhoff Interns: Charly Niehus
                    Costanza Parigi Lara Ansell Claire Aline-Lorant Gaétan
                    Langlois-Meurinne
                  </p>
                  <p>Translators: Ranav Adhikari, Anish Dhakal, Evan Pradhan</p>
                  <p>
                    Nepal Production Team Production Coordination: Felix
                    Gaedtke, Gayatri Parameswaran Line Producers Nepal: Deepak
                    Tolange, Sudeep Bhaju Volumetric Video Filming &
                    Photogrammetry Scanning: Felix Gaedtke, Gayatri Parameswaran
                    Director of Photography & Drone Pilot: Aditya Thakuri Sound
                    Recordist & Production Assistant : Mia von Kolpakow Kusunda
                    Linguistics Researcher: Uday Raj Aale Driver & Crowd
                    Manager: Dharmendra Shakya Electrician: Pramod Magar Gaule
                  </p>
                  <p>
                    Installation Designer: Hansraj Maharawal Projection Video
                    Artist: Gaétan Langlois-Meurinne
                  </p>
                  <p>
                    Prototype Design & Development: Matias Brunacci Prototype 3D
                    Animation: Theresa Schlag
                  </p>
                  <p>
                    Medienboard Berlin-Brandenburg ZHdK Swedish Film Institute
                    Artizen(Kaleidoscope) Vulcan Productions Viveport
                  </p>
                  <p>
                    Financial support by the Swedish Film Institute, film
                    commissioner Juan Pablo Libossart
                  </p>
                  <p>
                    高雄市電影館 VR FILM LAB LOGO (BLACK AND WHITE): Kaohsiung
                    Film Archive VR FILM LAB 館長 楊孟穎 Director: Meng-Yin Yang
                    副館長 黃晧傑 Deputy Director: Hao-Jie Huang 製作企劃部組長
                    李懷瑾 Head of Production Planning Department: Grace
                    Huai-Chin Lee 製作協調 洪珷 Production Coordinator: Sebox Wu
                    Hung 國際發行 郭旻薇 International Distribution: Min-Wei Kuo
                    國際聯繫助理 李圻 Assistant International Contact: Chi Lee
                    行銷宣傳 王瑋蔞 Press Manager: Wei-Law Wang
                  </p>
                  <p>
                    Sundance MacArthur Augstein Stiftung Danish Arts Foundation
                    INVR Poké Poké Creative Fasad WeAreMachineMedia NICE
                    Creative realities.io
                  </p>
                  <p>
                    KUSUNDA was Supported In Part by Sundance Institute’s New
                    Frontier Lab Programs with a grant from the John D. and
                    Catherine T. MacArthur Foundation
                  </p>
                  <p>
                    EsoDoc DevLab DOKFest Forum Venice Production Bridge IDFA
                    DocLab Forum
                  </p>
                  <p>
                    Special thanks to Family of Gyani Maiya Kusunda Prem Bahadur
                    Kusunda Kamala Kusunda Principal of Kimichor School Rajan
                    Parajuli Dev Kumar Sunuwar, Indigenous Voices Nepal Kusunda
                    Association Esther Rothstegge Chantal Haunreiter Patrik
                    Vasiljev Trausti Birgisson Håkan Strandhag Ruthie Doyle
                    Kieran Medina Beata Calinska Stephanie Reuter David Romero
                    Kris Severson Heidi Gronauer Thomas Righetti Sabine
                    Bubeck-Paaz Fabrizio Galeazzi Shreepali Patel Pierre
                    Magistry Mike Robbins Harmke Hezeen Johan Reinhard Mandana
                    Seyfeddinipur Mark Turin Scatter and Depthkit Studio
                    Kiefholzstraße
                  </p>
                  <p>
                    © NowHere Media, Fasad, INVR, Poké Poké 2021 with stories
                    with Gyani Maiya Kusunda, Hima Kusunda and Lil Bahadur
                    Kusunda.
                  </p>
                </div> */}
              </>
            ) : (
              <>
                <p>
                  What makes a language fall asleep? And what does it take to
                  wake one up?
                </p>
                <p>
                  Speak to Awaken is a two part virtual reality series that
                  throws light on indigenous languages from Nepal and Taiwan
                  that find themselves at different stages on their reclamation
                  journey. Placing these stories side by side demonstrates how
                  it is micro communities that are leading the fight to be
                  heard, and offers a glimpse of the invaluable, unique
                  worldviews that these languages contain.
                </p>
              </>
            )}
          </div>
          <button onClick={this.props.closePopup}>exit</button>
        </div>
      </div>
    );
  }
}

export default About;
