import React, { Component } from 'react';

export default class InstructionsFlashCard extends Component {
  render() {
    return (
      <div className="popup">
        <div className="popup_wrapper">
          <div className="popup_inner">
            <h1>Instructions</h1>
            <p>Thank you for spending 5 minutes to help us train our AI model for Speak to Awaken.</p>
            <div>
              What you need:
              <ol>
                <li>Your voice</li>
                <li>Any smart device with a microphone</li>
              </ol>
            </div>
            <div>
              What you need to do:
              <ol>
                <li>
                  On the next page, click <i className="fas fa-volume-up shimmer" /> to hear the word you will record.
                </li>
                <li>
                  When you are ready to record, click <i className="fas fa-microphone" />. After the count in, say the
                  word.
                </li>
                <li>If you're happy with the recording, click 'next word'.</li>
                <li>You can re-record as many times as you like.</li>
              </ol>
            </div>
          </div>
          <button onClick={this.props.closePopup}>exit</button>
        </div>
      </div>
    );
  }
}
