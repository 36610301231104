export const SHUFFLE = (Arr) => {
  let counter = Arr.length;
  while (counter > 0) {
    let index = Math.floor(Math.random() * counter);
    counter--;
    let temp = Arr[counter];
    Arr[counter] = Arr[index];
    Arr[index] = temp;
  }
  return Arr;
};

export const GET_WORDS = () => {
    return [...SHUFFLE(WORDS_PRIMARY), ...SHUFFLE(WORDS_SECONDARY)];
}

export const FUNFACTS = [
  'There is no word for the colour green in the Kusunda language.',
  '86% of people use Asian or European languages.',
  'About 0.1% of the world population are responsible for keeping one half of the world’s languages alive.',
  'The Guugu Ymithirr people of Australia traditionally had no words for left and right and only used North, South, East and West for direction.',
  'There are up to 70 languages that can be whistled.',
  "Indigenous peoples make up less than 6% of the global population, but speak more than 4,000 of the world's languages.",
  'More than twice the number of languages spoken across Europe can be found in Papua New Guinea alone.',
  'Hawaiians have more than 200 words for rain.',
  '“The language, the whole culture of the Lakota, comes from the song of our heartbeat. It’s not something that can quickly be put into words. It’s a feeling, it’s a prayer, it’s a thought, it’s an emotion—all of these things are in the language.” —Larry Swalley, Lakota',
];

const WORDS_PRIMARY = [
    {
        text: '/mjak​/',
        definition: 'Tiger',
        audio: ['myak.wav'],
        language: 'Kusunda',
      },
      {
        text: '/səi​/',
        definition: 'Bear',
        audio: ['sai.wav'],
        language: 'Kusunda',
      },
      {
        text: '/mjeka​/',
        definition: 'Alone',
        audio: ['myaka.wav'],
        language: 'Kusunda',
      },
      {
        text: '/h​u​mə.əɡo​/',
        definition: 'To persuade',
        audio: ['omago.wav'],
        language: 'Kusunda',
        funfact: "There is no simple word for love in Kusunda; an often substituted term is 'persuade'.",
      },
      {
        text: '/fə.naŋ/',
        definition: '鹿 / Deer',
        audio: ['fnang.wav', 'Fnang_standard_b.wav'],
        language: 'Sirayan',
      },
      {
        text: '/vaw.uŋ/',
        definition: '森林 / forest',
        audio: ['vawung.wav', 'Vawung_standard_b.wav'],
        language: 'Sirayan',
      },
]

const WORDS_SECONDARY = [
  {
    text: '/soʣak​/',
    definition: 'Greetings',
    audio: ['sojak.wav'],
    language: 'Kusunda',
  },
  {
    text: '/ɡilaŋg​/',
    definition: 'Forest',
    audio: ['gilang.wav'],
    language: 'Kusunda',
  },
  {
    text: '/daŋbei​/',
    definition: 'Shaman',
    audio: ['dangbai.wav'],
    language: 'Kusunda',
  },
  {
    text: '/mi.əɡo​/',
    definition: 'To forget',
    audio: ['miago.wav'],
    language: 'Kusunda',
  },
  {
    text: '/ɡipən​/',
    definition: 'Mother tounge',
    audio: ['gipan.wav'],
    language: 'Kusunda',
  },
  {
    text: '/eiɡi.əɡo​/',
    definition: 'Revive',
    audio: ['eigiago.wav'],
    language: 'Kusunda',
  },
  {
    text: '/k​u​s​u​nda​/',
    definition: 'Kusunda',
    audio: ['kusunda.wav'],
    language: 'Kusunda',
  },
  {
    text: '/imba​/',
    definition: 'To forget',
    audio: ['imba.wav'],
    language: 'Kusunda',
  },

  //   Siraya
  {
    text: '/mu.su/',
    definition: '發芽 / to bud',
    audio: ['Musu_standard_b.wav'],
    language: 'Sirayan',
  },
  {
    text: '/da.raŋ/',
    definition: '道路 / road / way',
    audio: ['darang.wav', 'Darang_standard_b.wav'],
    language: 'Sirayan',
  },
  {
    text: '/tə.mi.ko/',
    definition: 'To run with bells',
    audio: ['tmikog.wav', 'Tmikog_standard_b.wav'],
    language: 'Sirayan',
    funfact:
      'Sirayan people wore bells while hunting deer in order to synchronise their steps  and to shock the deer, making them easier targets.',
  },

  //   {
  //     text: '/vu.riŋ.an/',
  //     definition: '竹林 / Bamboo forest',
  //     audio: ['vurigan.wav', 'Vuligan_standard_b.wav'],
  //     language: 'Sirayan',
  //   },
  {
    text: '/vu.rix/',
    definition: '竹子 / Bamboo',
    audio: ['Vurig_standard_b.wav'],
    language: 'Sirayan',
  },
  {
    text: '/vu.kin/',
    definition: '山 / mountain',
    audio: ['vukin.wav', 'Vukin_standard_b.wav'],
    language: 'Sirayan',
  },
  {
    text: '/pa.ra.nag/',
    definition: '樹木 / tree',
    audio: ['paranax.wav', 'Paranax_standard_b.wav'],
    language: 'Sirayan',
  },
  {
    text: '/za.rox/',
    definition: '河流/ river/creek',
    audio: ['Zarok_standard_b.wav'],
    language: 'Sirayan',
  },
  {
    text: '/pu.ra.rej/',
    definition: '雲 / cloud',
    audio: ['puraray.wav', 'Puraray_standard_b.wav'],
    language: 'Sirayan',
  },
  {
    text: '/a.jam/',
    definition: '鳥 / bird',
    audio: ['ayam.wav', 'Ayam_standard_b.wav'],
    language: 'Sirayan',
  },
];
