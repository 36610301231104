import React, { Component } from "react";

class About extends Component {
  createPressElements(quotes) {
    return quotes.map((item) => {
      return (
        <p>
          <h3>
            <i>{item.quote}</i>
          </h3>
          <br />
          <a
            className="aboutpage__link"
            style={{ opacity: "100%", color: "#222222", paddingBottom: "10px" }}
            href={item.link}
          >
            {item.source}
          </a>
        </p>
      );
    });
  }

  createAwardElements(quotes) {
    return quotes.map((item) => {
      return (
        <p>
          <h3>
            <i>{item.quote}</i>
          </h3>
          <br />
          <a
            className="aboutpage__link"
            style={{ opacity: "100%", color: "#222222", paddingBottom: "10px" }}
          >
            {item.source}
          </a>
        </p>
      );
    });
  }

  render() {
    return (
      <div className="popup">
        <div className="popup_wrapper">
          <div className="popup_inner">
            <h1>Press</h1>
            {this.createPressElements(this.props.pressQuotes)}
            <h1>Awards</h1>
            {this.createAwardElements(this.props.awards)}
          </div>
          <button onClick={this.props.closePopup}>exit</button>
        </div>
      </div>
    );
  }
}

export default About;
