import React, { Component } from "react";

class About extends Component {
  createScreeningsList(items) {
    let now = new Date();
    let out_list = items.map((element) => {
      let dateCopy = element.date.includes("-")
        ? element.date.split("-")[1]
        : element.date;
      let anotherCopy = dateCopy.split(".");
      let screeningDate =
        anotherCopy.length > 2
          ? new Date(
              anotherCopy[2],
              parseInt(anotherCopy[1]) - 1,
              anotherCopy[0]
            )
          : new Date(anotherCopy[1], parseInt(anotherCopy[0]) - 1);
      console.log(screeningDate);
      let opacity = screeningDate < now ? "40%" : "100%";
      return (
        <li
          style={{ paddingBottom: "10px", opacity: opacity }}
          key={element.date}
        >
          <div style={{ fontWeight: "bold" }}>{element.date}</div>
          {element.text}
        </li>
      );
    });
    return out_list;
  }

  render() {
    return (
      <div className="popup">
        <div className="popup_wrapper">
          <div className="popup_inner">
            <h1>Screenings</h1>
            <ul>{this.createScreeningsList(this.props.screenings)}</ul>
          </div>
          <button onClick={this.props.closePopup}>exit</button>
        </div>
      </div>
    );
  }
}

export default About;
