import React, { Component } from 'react';

export default class Start extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="instructions">
        <div className="instructions__container">
          <p>Thank you for spending 5 minutes to help us train our AI model for Speak to Awaken.</p>
          <div>
            What you need:
            <ol>
              <li>Your voice</li>
              <li>Any smart device with a microphone</li>
            </ol>
          </div>
          <div>
            What you need to do:
            <ol>
              <li>
                On the next page, click <i className="fas fa-volume-up shimmer" /> to hear the word you will record.
              </li>
              <li>
                When you are ready to record, click <i className="fas fa-microphone" />. After the count in, say the
                word.
              </li>
              <li>If you're happy with the recording, click 'next'.</li>
              <li>You can re-record as many times as you like.</li>
            </ol>
          </div>
          <button className="instructions__btn btn-main btn-instructions-card" onClick={this.props.onBegin}>
            I'm ready, let's go!
          </button>
        </div>
      </div>
    );
  }
}
