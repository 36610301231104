export const API =
  "https://f70wnsdybh.execute-api.eu-central-1.amazonaws.com/prod";

export const kusundaScreenings = [
  {
    date: "22-24.09.2022",
    text: "THEATER CHUR",
  },
  {
    date: "06-11.09.2022",
    text: "FANTOCHE",
  },
  {
    date: "17-19.06.2022",
    text: "THE BRITISH ACADEMY SUMMER SHOWCASE",
  },
  {
    date: "15-19.06.2022",
    text: "SILBERSALZ FESTIVAL",
  },
  {
    date: "30.06-24.10.2022",
    text: "HORIZONS VR @ PHI CENTRE",
  },
  {
    date: "08-10.06.2022",
    text: "RE:PUBLICA",
  },
  {
    date: "10-15.05.2022",
    text: "ANIFILM",
  },
  {
    date: "17-23.01.2022",
    text: "FIPADOC",
  },
  {
    date: "12.2021-01.2022",
    text: "KUSUNDA COMMUNITY SCREENING",
  },
  {
    date: "06-15.12.2021",
    text: "RED SEA INTERNATIONAL FILM FESTIVAL",
  },
  {
    date: "17-28.11.2021",
    text: "INTERNATIONAL DOCUMENTARY FILM FESTIVAL AMSTERDAM",
  },
  {
    date: "10-14.11.2021",
    text: "REFRESH FESTIVAL ZHDK",
  },
  {
    date: "27.10-15.11.2021",
    text: "RAINDANCE FILM FESTIVAL",
  },
  {
    date: "05-08.11.2021",
    text: "NEW CHITOSE AIRPORT INTERNATIONAL ANIMATION FESTIVAL",
  },
  {
    date: "28-30.10.2021",
    text: "LAGOS INTERNATIONAL FESTIVAL OF ANIMATION",
  },
  {
    date: "15-31.10.2021",
    text: "KAOHSIUNG FILM FESTIVAL",
  },
  { date: "14-16.10.2021", text: "VRE21" },
  { date: "01-11.09.2021", text: "78TH VENICE INTERNATIONAL FILM FESTIVAL" },
  {
    date: "14-21.08.2021",
    text: "11TH BEIJING INTERNATIONAL FILM FESTIVAL (postponed)",
  },
  {
    date: "08-18.07.2021",
    text: "BUCHEON INTERNATIONAL FANTASTIC FILM FESTIVAL",
  },
  { date: "09-20.06.2021", text: "20TH TRIBECA FILM FESTIVAL" },
  {
    date: "02.2021",
    text: "KUSUNDA COMMUNITY SCREENING",
  },
  {
    date: "31.10-08.11.2020",
    text: "KAOHSIUNG FILM FESTIVAL",
  },
];

export const sarayaScreenings = [
  {
    date: "09-13.08.2021",
    text: "THE SIGGRAPH IMMERSIVE PAVILION",
  },
  {
    date: "08-18.07.2021",
    text: "BEYOND REALITY XR, BUCHEON INTERNATIONAL FANTASTIC FILM FESTIVAL",
  },
  {
    date: "29.01-30.05.2021",
    text: "EXHIBITION AT MUSEUM OF ARCHAEOLOGY, TAINAN BRANCH OF THE NATIONAL MUSEUM OF PREHISTORY",
  },
  {
    date: "16.10-01.11.2020",
    text: "KAOHSIUNG FILM FESTIVAL",
  },
];

export const kusundaPressQuotes = [
  {
    quote: "...breathtaking volumetric VR film",
    source:
      "‘Voice-driven VR Film Kusunda teaches you a dying language’ by Kyle Melnick in VRScout.",
    link: "https://vrscout.com/news/vr-film-kusunda-teaches-dying-language/",
  },
  {
    quote: "a wonderfully timely documentary",
    source:
      "‘New Paths Forward: The Immersive and Interactive Works at Tribeca 2021’ by Randy Astle in Filmmaker Magazine.",
    link: "https://filmmakermagazine.com/111864-new-paths-forward-the-immersive-and-interactive-works-at-tribeca-2021/#.YRzdZd9CSUk",
  },
  {
    quote:
      "a potent mix of volumetric capture technology, CGI, and the documentary chops",
    source: "‘Overtime: Kusunda’ by Noah Nelson on the No Proscenium Podcast.",
    link: "https://shows.acast.com/no-proscenium/episodes/overtime-kusunda",
  },
];

export const kusundaAwards = [
  {
    quote: "SMART Award",
    source: "2022 FIPADOC",
  },
  {
    quote: "Best Heritage XP",
    source: "2021 Stereopsia Crystal Owl Awards",
  },
  {
    quote: "Storyscapes Award",
    source: "20th Tribeca Film Festival",
  },
  {
    quote: "Best Documentary Experience",
    source: "2021 Raindance Film Festival",
  },
  {
    quote: "Grand Prize",
    source: "2021 VRNow Awards",
  },
  {
    quote: "Best Immersive Cinema",
    source: "2021 VRNow Awards",
  },
  {
    quote: "Best Interactive Entertainment",
    source: "2021 nextReality Contest",
  },
];
