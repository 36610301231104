import React, { Component } from "react";

class VideoPopup extends Component {
  render() {
    return (
      <div className="popup">
        <div className="popup_wrapper">
          {/* <div className="popup_inner"> */}
          <iframe
            className="aboutpage__video"
            title={this.props.source}
            src={this.props.source}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
          {/* </div> */}
          <button onClick={this.props.closePopup}>exit</button>
        </div>
      </div>
    );
  }
}

export default VideoPopup;
