import React, { Component } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import CountIn from "../CountIn/CountIn";
// import Oscilloscope from '../Oscilloscope/Oscilloscope';
import { API } from "../constants";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";

const Mp3Recorder = new MicRecorder({ bitRate: 320 });

export default class Recorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      counting: false,
      isRecording: false,
      blob: "",
      blobURL: "",
      isBlocked: false,
      hasRecorded: false,
    };
    this.handleUpload = this.handleUpload.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  componentDidMount() {
    if (!this.state.isBlocked) {
      navigator.mediaDevices.getUserMedia(
        { audio: true },
        () => {
          console.log("Permission Granted");
          this.setState({ isBlocked: false });
        },
        () => {
          console.log("Permission Denied");
          this.setState({ isBlocked: true });
        }
      );
    }
  }

  beginCountIn = () => {
    this.setState({ counting: true });
    setTimeout(() => {
      this.start();
    }, 3000);
  };

  start = () => {
    if (this.state.isBlocked) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          this.setState({
            counting: false,
            isRecording: true,
            hasRecorded: true,
          });
          this.props.grabIsRecordingState(true);
        })
        .catch((e) => console.error(e));
      setTimeout(() => {
        this.stop();
      }, 4000);
    }
  };

  stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);

        this.setState({
          blobURL: blobURL,
          blob: blob,
          isRecording: false,
          counting: false,
        });

        this.props.grabIsRecordingState(false);
      })
      .catch((e) => console.log(e));
  };

  play = () => {
    document.getElementById("audio").play();
  };

  handleUpload() {
    // api will use the first audio file name to file the recording under
    let word = this.props.currentWord.audio[0].replace(".wav", "");
    var reader = new FileReader();

    reader.readAsDataURL(this.state.blob);
    reader.onloadend = function () {
      var base64data = reader.result;
      fetch(API + "/speaktheunspoken-upload-audio", {
        method: "POST",
        body: JSON.stringify({
          audio_data: base64data,
          word: word,
        }),
      })
        .then((result) => result.json())
        .then((json) => {
          console.log(json);
        });
    };
  }

  handleNextWord() {
    this.handleUpload();
    if (this.state.hasRecorded) {
      this.setState({
        hasRecorded: false,
        blobURL: "",
        blob: "",
      });
      this.props.handleNextWord();
    }
  }

  render() {
    return (
      <>
        <div className="audio-recorder">
          <div className="btn-wrapper">
            <div className={`audio-recorder__ctrl-btn`}>
              <Tooltip
                title="Record your voice"
                position="right"
                trigger="mouseenter"
                theme="light"
                arrow="true"
              >
                {!this.state.isRecording ? (
                  <button
                    onClick={this.beginCountIn}
                    disabled={this.state.isRecording}
                    className={`audio-recorder__record-btn btn-ctrl ${this.props.currentLang}`}
                  >
                    {this.state.counting ? (
                      <CountIn />
                    ) : (
                      <i className="fas fa-microphone"></i>
                    )}
                  </button>
                ) : (
                  <button
                    disabled={!this.state.isRecording}
                    className={`audio-recorder__stop-btn btn-ctrl ${this.props.currentLang}`}
                  >
                    <i className="fas fa-microphone"></i>
                  </button>
                )}
              </Tooltip>
            </div>

            <Tooltip
              title="Listen back"
              position="right"
              trigger="mouseenter"
              theme="light"
              arrow="true"
            >
              <button
                onClick={this.play}
                disabled={!this.state.blobURL}
                className="play"
              >
                <i className="fas fa-play"></i>
              </button>
            </Tooltip>
            <button
              className="next"
              onClick={this.handleNextWord.bind(this)}
              disabled={!this.state.blobURL}
            >
              Next
            </button>
            <audio src={this.state.blobURL} id="audio" />
          </div>
        </div>
      </>
    );
  }
}
