import React, { Component } from 'react';

export default class ProgressBar extends Component {
  render() {
    const { words, currentLang } = this.props;
    const totalWordCount = this.props.totalWordCount + 1;
    return (
      <div className="progress-bar">
        <div
          className={`progress-bar__progress ${currentLang}`}
          style={{ width: `${(totalWordCount / words) * 100}%` }}
        ></div>
      </div>
    );
  }
}
