import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Start from "./Start/Start";
import LandingPage from "./LandingPage/LandingPage";
import SirayaMain from "./SirayaMain/SirayaMain";
import KusundaMain from "./KusundaMain/KusundaMain";
import CompletionMessage from "./CompletionMessage/CompletionMessage";

export default class App extends Component {
  componentWillMount() {
    if (navigator.userAgent.match("CriOS")) {
      alert("This app doesn't support Chrome iOS. Please use Safari");
    }
  }
  render() {
    return (
      <Router>
        <div className="container">
          <Switch>
            <Route exact path="/">
              <LandingPage />
            </Route>
            <Route exact path="/siraya">
              <SirayaMain />
            </Route>
            <Route exact path="/kusunda">
              <KusundaMain />
            </Route>
            <Route path="/start">
              <Start />
            </Route>
            <Route path="/complete">
              <CompletionMessage />
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}
