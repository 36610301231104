import React, { Component } from 'react';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import ProgressBar from '../ProgressBar/ProgressBar';

export default class FlashCard extends Component {
  render() {
    return (
      <div className="flash-card">
        <div className="flash-card__top-panel">
          {/* <button className="btn-main btn-instructions-card">Instructions</button> */}
          <div className="read-instructions" onClick={this.props.showInstructions}>
            <Tooltip title="Instructions" position="left" trigger="mouseenter" theme="light" arrow="true">
              <i className="fas fa-list-ul"></i>
            </Tooltip>
          </div>
          <div className="curr-language">
            <Tooltip title="Current language" position="right-end" trigger="mouseenter" theme="light" arrow="true">
              {this.props.currentWord.language}
            </Tooltip>
          </div>
        </div>

        <div className="current-word">
          <div className="word-display">{this.props.currentWord.text}</div>
          <div className={this.props.playBackClicked ? 'blurred-bg hidden' : 'blurred-bg'}></div>
          <Tooltip title="Listen to the current word" position="top" trigger="mouseenter" theme="light" arrow="true">
            <button onClick={this.props.playExample} className={`word-play ${this.props.currentWord.language}`}>
              <i className="fas fa-volume-up shimmer"></i>
            </button>
          </Tooltip>
        </div>
        {/* <div className="divider"></div> */}
        <ProgressBar
          words={this.props.totalWords}
          totalWordCount={this.props.totalWordCount}
          currentLang={this.props.currentLang}
        />
        <div className="info-bar">
          <div className="definition">
            <b>Definition:</b> {this.props.currentWord.definition}
          </div>
          <div className="words-left">
            {this.props.totalWordCount + 1}/{this.props.totalWords}
          </div>
        </div>
      </div>
    );
  }
}
