import React, { Component } from "react";

class HowItWorks extends Component {
  render() {
    return (
      <div className="popup">
        <div className="popup_wrapper">
          <div className="popup_inner">
            <h1>How it works</h1>
            <p>
              In each episode of Speak to Awaken, users speak words in an
              indigenous language to stand in solidarity with the community
              behind its reclamation. This act triggers artificial intelligence
              (AI) enabled voice based interactions, which carve the user’s path
              through the narrative, unlocking storyworlds related to the
              specific words.
            </p>
            <p>
              The interactive feature is enabled by a neural network trained for
              speech recognition. To function, it needs to collect as many
              diverse audio samples of each word used in the series as possible.
            </p>
            <p>
              That’s where you come in. Get involved, and lend your voice to the
              reclamation of the special languages we highlight in this
              documentary series.
            </p>
          </div>
          <button onClick={this.props.closePopup}>exit</button>
        </div>
      </div>
    );
  }
}

export default HowItWorks;
