import React, { Component } from "react";

import About from "../shared/About/About";
import VideoPopup from "../shared/VideoPopup/VideoPopup";
import SirayaImg from "../assets/graphics/siraya/siraya_front.png";
import pokeLogo from "../assets/images/pokepoke_logo_white.png";
import Screenings from "../shared/Screenings/Screenings";
import Press from "../shared/Press/Press";
import Nav from "../shared/Nav/Nav";

import { sarayaScreenings, kusundaPressQuotes } from "../constants";

class SirayaMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: "main",
    };
    this.showPopup = this.showPopup.bind(this);
  }

  showPopup(label) {
    console.log(label);
    this.setState({ show: label });
  }

  closePopup() {
    this.setState({ show: "main" });
  }

  render() {
    return (
      <div className="aboutpage">
        <Nav showHowItWorks={false} showBack={true} />
        <h1>Episode 1</h1>
        <img src={SirayaImg} className="kusunda-img" alt="siraya" />
        <div className="aboutpage__tagline">
          <div className="aboutpage__info">
            <button onClick={() => this.showPopup("about")}>About</button>
          </div>
          <div className="aboutpage__info">
            <button onClick={() => this.showPopup("trailer")}>Trailer</button>
          </div>
          <div className="aboutpage__info">
            <button onClick={() => this.showPopup("screenings")}>
              Screenings
            </button>
          </div>
          {/* <div className="aboutpage__info">
            <button onClick={() => this.showPopup("makingOf")}>
              The Making Of
            </button>
          </div>
          <div className="aboutpage__info">
            <button onClick={() => this.showPopup("press")}>Press</button>
          </div> */}
        </div>
        <div className="aboutpage__imprint">
          {/* <div className="aboutpage__imprint-nowhere-logo"> */}
          <img src={pokeLogo} className="aboutpage__poke-logo-img" alt="logo" />
          {/* </div> */}
        </div>

        {this.state.show === "about" ? (
          <About closePopup={this.closePopup.bind(this)} episode="siraya" />
        ) : this.state.show === "trailer" ? (
          <VideoPopup
            source={
              "https://www.dropbox.com/s/jsp4zyixu64ssnt/Diving%20into%20Siraya_trailer.mp4?raw=1"
            }
            closePopup={this.closePopup.bind(this)}
          />
        ) : this.state.show === "screenings" ? (
          <Screenings
            screenings={sarayaScreenings}
            closePopup={this.closePopup.bind(this)}
          />
        ) : this.state.show === "makingOf" ? (
          <VideoPopup
            source="https://player.vimeo.com/video/592064998?h=859ad7b778"
            closePopup={this.closePopup.bind(this)}
          />
        ) : this.state.show === "press" ? (
          <Press
            pressQuotes={kusundaPressQuotes}
            closePopup={this.closePopup.bind(this)}
          />
        ) : null}
      </div>
    );
  }
}

export default SirayaMain;
