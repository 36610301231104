import React, { Component } from "react";

// Logos
import nowHereLogo from "../assets/images/NowHereLogo.png";
import PokePokeLogo from "../assets/images/PokePokeLogo.png";
import CCDFLogo from "../assets/images/partner_logos/CCDF_LOGO-01.png";
import KaohsiungLogo from "../assets/images/partner_logos/Kaohsiung.png";
import ZHdKLogo from "../assets/images/partner_logos/ZHdK_Logo.png";
import fasadLogo from "../assets/images/partner_logos/fasad.png";
import HTCLogo from "../assets/images/partner_logos/HTC.png";
import INVRLogo from "../assets/images/partner_logos/INVR.png";
import KaleidoscopeLogo from "../assets/images/partner_logos/Kaleidoscope.png";
import medienboardLogo from "../assets/images/partner_logos/medienboard.png";
import realitiesLogo from "../assets/images/partner_logos/realities_logo.png";
import RudolfLogo from "../assets/images/partner_logos/Rudolf_Augstein.png";
import MacArthurLogo from "../assets/images/partner_logos/MacArthur_DARKBG.png";
import SKFLogo from "../assets/images/partner_logos/SKF_logo.png";
import VulcanLogo from "../assets/images/partner_logos/vulcan_productions.png";
import CadesLogo from "../assets/images/partner_logos/cades-logo.png";

export default class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: "main",
    };
  }

  render() {
    return (
      <div className="landingpage">
        <div className="landingpage__bg"></div>
        <div className="landingpage__header">
          <h1>Speak to Awaken</h1>
        </div>
        <div className="landingpage__tagline">
          <h2>A voice-driven VR documentary series</h2>
        </div>
        <div className="landingpage__info">
          <a href="/siraya">
            <h3 className="landingpage__cardheader">Episode 1:</h3>
            <br />
            <h2 className="landingpage__cardsubheader">Diving into Siraya</h2>
          </a>
          <a href="/kusunda">
            <h3 className="landingpage__cardheader">Episode 2:</h3>
            <br />
            <h2 className="landingpage__cardsubheader">KUSUNDA</h2>
          </a>
        </div>
        <div className="landingpage__get-involved">
          <a href="/start" className="landing-btn">
            Lend Your Voice
          </a>
        </div>

        <div className="landingpage__imprint">
          <div className="landingpage__imprint-nowhere-logo">
            <img
              src={nowHereLogo}
              className="landingpage__logo-img"
              alt="logo"
            />
          </div>
          <div className="landingpage__imprint-Vulcan-logo">
            <img
              src={VulcanLogo}
              className="landingpage__logo-img"
              alt="logo"
            />
          </div>
          <div className="landingpage__imprint-SKF-logo">
            <img src={SKFLogo} className="landingpage__logo-img" alt="logo" />
          </div>
          <div className="landingpage__imprint-MacArthur-logo">
            <img
              src={MacArthurLogo}
              className="landingpage__logo-img"
              alt="logo"
            />
          </div>
          <div className="landingpage__imprint-Rudolf-logo">
            <img
              src={RudolfLogo}
              className="landingpage__logo-img"
              alt="logo"
            />
          </div>
          <div className="landingpage__imprint-realities-logo">
            <img
              src={realitiesLogo}
              className="landingpage__logo-img"
              alt="logo"
            />
          </div>
          <div className="landingpage__imprint-Medienboard-logo">
            <img
              src={medienboardLogo}
              className="landingpage__logo-img"
              alt="logo"
            />
          </div>
          <div className="landingpage__imprint-Kaleidoscope-logo">
            <img
              src={KaleidoscopeLogo}
              className="landingpage__logo-img"
              alt="logo"
            />
          </div>
          <div className="landingpage__imprint-INVR-logo">
            <img src={INVRLogo} className="landingpage__logo-img" alt="logo" />
          </div>
          <div className="landingpage__imprint-HTC-logo">
            <img src={HTCLogo} className="landingpage__logo-img" alt="logo" />
          </div>
          <div className="landingpage__imprint-FASAD-logo">
            <img src={fasadLogo} className="landingpage__logo-img" alt="logo" />
          </div>
          <div className="landingpage__imprint-ZHdK-logo">
            <img src={ZHdKLogo} className="landingpage__logo-img" alt="logo" />
          </div>
          <div className="landingpage__imprint-Kaohsiung-logo">
            <img
              src={KaohsiungLogo}
              className="landingpage__logo-img"
              alt="logo"
            />
          </div>
          <div className="landingpage__imprint-CCDF-logo">
            <img src={CCDFLogo} className="landingpage__logo-img" alt="logo" />
          </div>
          <div className="landingpage__imprint-Cades-logo">
            <img src={CadesLogo} className="landingpage__logo-img" alt="logo" />
          </div>
          <div className="landingpage__imprint-nowhere-logo">
            <img
              src={PokePokeLogo}
              className="landingpage__logo-img"
              alt="logo"
            />
          </div>
        </div>
      </div>
    );
  }
}
