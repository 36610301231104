import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/fontawesome-free-solid";

export default class Nav extends React.Component {
  render() {
    return (
      <nav className="nav">
        {!this.props.showBack ? (
          <a href="/" className="nav__title">
            Speak to Awaken
          </a>
        ) : null}
        {/* <div className="nav__logo">
          <img src={logo} className="nav__logo-img"/>
        </div> */}
        {this.props.showHowItWorks ? (
          <div className="nav__btns">
            <a href="/" className="btn-main btn-nav">
              <FontAwesomeIcon icon={faArrowLeft} />
            </a>
            <button
              className="btn-main btn-nav"
              onClick={this.props.showHowItWorks}
            >
              How It Works
            </button>
          </div>
        ) : this.props.showBack ? (
          <div className="nav__btns">
            <a href="/" className="btn-main btn-nav">
              <FontAwesomeIcon icon={faArrowLeft} />
            </a>
          </div>
        ) : null}
      </nav>
    );
  }
}
