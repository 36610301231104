import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Instructions from "../shared/Instructions/Instructions";
import Nav from "../shared/Nav/Nav";
import { SHUFFLE, GET_WORDS, FUNFACTS } from "./constants";
import Recorder from "../Recorder/Recorder";
import FlashCard from "../FlashCard/FlashCard";
import FunFact from "../FunFact/FunFact";
import HowItWorks from "../shared/HowItWorks/HowItWorks";
import InstructionsFlashCard from "../shared/InstructionsFlashCard/InstructionsFlashCard";
import "../styles.scss";
import "../normalize.scss";

const kusundaBG = ["hema"];
const sirayaBG = ["sirayan"];

export default class Start extends Component {
  constructor(props) {
    super(props);
    this.state = {
      words: GET_WORDS(),
      hasBegun: false,
      totalWordCount: 0,
      showComplete: false,
      currentWord: null,
      funFacts: SHUFFLE(FUNFACTS),
      funFactIndex: 0,
      showFact: false,
      audio: null,
      playBackClicked: false,
      show: "main",
    };
    this.onBegin = this.onBegin.bind(this);
    this.handleNextWord = this.handleNextWord.bind(this);
    this.grabIsRecordingState = this.grabIsRecordingState.bind(this);
    this.playExample = this.playExample.bind(this);
    this.handleFunFact = this.handleFunFact.bind(this);
    this.showHowItWorks = this.showHowItWorks.bind(this);
  }

  componentWillMount() {
    this.setState({
      currentWord: this.state.words[0],
      audio: new Audio(`./audio/${SHUFFLE(this.state.words[0].audio)[0]}`),
    });
    // window.addEventListener('beforeunload', (ev) => {
    //   ev.preventDefault();
    //   return (ev.returnValue = 'Wait! We know this takes a bit of time, but we really need you to complete all of the recordings for this to be effective.');
    // });
  }

  onBegin() {
    this.setState({ hasBegun: true });
  }

  handleFunFact() {
    if (this.state.currentWord.funFact) {
      this.setState({
        currentFunFact: this.state.currentWord.funFact,
        showFact: true,
      });
    } else {
      this.setState({
        currentFunFact: this.state.funFacts[this.state.funFactIndex],
        showFact: true,
      });
    }
  }

  closeFact() {
    if (this.state.totalWordCount + 1 < this.state.words.length) {
      this.setState({
        funFactIndex: this.state.funFactIndex + 1,
        totalWordCount: this.state.totalWordCount + 1,
        currentWord: this.state.words[this.state.totalWordCount + 1],
        audio: new Audio(
          `./audio/${
            SHUFFLE(this.state.words[this.state.totalWordCount + 1].audio)[0]
          }`
        ),
        showFact: false,
      });
    }
    if (this.state.totalWordCount + 1 === this.state.words.length) {
      this.setState({
        showComplete: true,
        showFact: false,
      });
    }
  }

  handleNextWord() {
    if ((this.state.totalWordCount + 1) % 3 === 0) {
      this.handleFunFact();
    } else {
      if (this.state.totalWordCount + 1 < this.state.words.length) {
        this.setState({
          totalWordCount: this.state.totalWordCount + 1,
          currentWord: this.state.words[this.state.totalWordCount + 1],
          audio: new Audio(
            `./audio/${
              SHUFFLE(this.state.words[this.state.totalWordCount + 1].audio)[0]
            }`
          ),
        });
      }
      if (this.state.totalWordCount + 1 === this.state.words.length) {
        this.setState({ showComplete: true });
      }
    }
  }

  handleComplete = () => {
    return <Redirect to="/complete" />;
  };

  playExample = () => {
    this.state.audio.play();
    this.setState({ playBackClicked: true });
  };

  grabIsRecordingState = (isRecording) => {
    this.setState({ isRecording: isRecording });
  };

  showHowItWorks() {
    this.setState({ show: "howItWorks" });
  }

  showInstructions() {
    this.setState({ show: "instructions" });
  }

  closePopup() {
    this.setState({ show: "main" });
  }

  render() {
    let language = this.state.currentWord.language;
    let randomBG;
    if (language === "Kusunda") {
      console.log("Kusunda bg");
      randomBG = kusundaBG[Math.floor(Math.random() * kusundaBG.length)];
    } else {
      console.log("Sirayan bg");
      randomBG = sirayaBG[Math.floor(Math.random() * sirayaBG.length)];
    }
    return (
      <div className="start-page">
        {this.state.showComplete ? this.handleComplete() : null}
        <Nav showHowItWorks={this.showHowItWorks} />

        {!this.state.hasBegun && <Instructions onBegin={this.onBegin} />}
        {this.state.hasBegun && (
          <div className={`recording-page ${language}`}>
            <div className={`randomized-bg ${randomBG}`}></div>

            <div className="recorder-component">
              <FlashCard
                currentWord={this.state.currentWord}
                totalWordCount={this.state.totalWordCount}
                totalWords={this.state.words.length}
                playExample={this.playExample}
                isRecording={this.state.isRecording}
                playBackClicked={this.state.playBackClicked}
                currentLang={language}
                showInstructions={this.showInstructions.bind(this)}
              />
              <Recorder
                currentWord={this.state.currentWord}
                currentLang={language}
                handleNextWord={this.handleNextWord}
                grabIsRecordingState={this.grabIsRecordingState}
              />
            </div>
          </div>
        )}
        {this.state.showFact && (
          <FunFact
            closePopup={this.closeFact.bind(this)}
            funFact={this.state.funFacts[this.state.funFactIndex]}
          />
        )}
        {this.state.show === "howItWorks" ? (
          <HowItWorks closePopup={this.closePopup.bind(this)} />
        ) : this.state.show === "instructions" ? (
          <InstructionsFlashCard closePopup={this.closePopup.bind(this)} />
        ) : null}
      </div>
    );
  }
}
