import React, { Component } from 'react';

export default class FunFact extends Component {
  render() {
    return (
      <div className="funfact-popup">
        <div className="funfact-popup_wrapper">
          <div className="funfact-popup_inner">
            <h2>Did you know?</h2>
            <p>{this.props.funFact}</p>
          </div>
          <button onClick={this.props.closePopup}>Continue</button>
        </div>
      </div>
    );
  }
}
