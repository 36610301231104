import React, { Component } from "react";

export default class CompletionMessage extends Component {
  render() {
    return (
      <div className="popup complete">
        <div className="popup_wrapper">
          <div className="popup_inner">
            <h1>Thank you!</h1>
            <p>
              Thank you for donating your voice to Speak to Awaken. In order to
              receive updates regarding the release and availability of the
              series, please subscribe to our newsletter.
            </p>
          </div>
            <a
                className="back-btn"
                href="http://eepurl.com/haOzqX"
            >
                Subscribe to newsletter
            </a>
            <br/>
            <a
                className="back-btn"
                href="mailto:davisshu@gmail.com"
            >
                Contact Poké Poké Creative
            </a>
            <br/>
            <a
                className="back-btn"
                href="https://www.nowheremedia.net/#kusunda-section"
            >
                Back to NowHere Media
            </a>
        </div>
      </div>
    );
  }
}
