import React, { Component } from "react";

import About from "../shared/About/About";
import VideoPopup from "../shared/VideoPopup/VideoPopup";
import KusundaImg from "../assets/graphics/kusunda/KUSUNDA_official poster 20220221.jpg";
import nowHereLogo from "../assets/images/NowHere_horizontal_new_font_WHITE_NEW.png";
import Screenings from "../shared/Screenings/Screenings";
import Press from "../shared/Press/Press";
import Nav from "../shared/Nav/Nav";

import {
  kusundaScreenings,
  kusundaPressQuotes,
  kusundaAwards,
} from "../constants";

class KusundaMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: "main",
    };
    this.showPopup = this.showPopup.bind(this);
  }

  showPopup(label) {
    console.log(label);
    this.setState({ show: label });
  }

  closePopup() {
    this.setState({ show: "main" });
  }

  render() {
    return (
      <div className="aboutpage">
        <Nav showHowItWorks={false} showBack={true} />
        <h1 className="aboutpage__header">Episode 2</h1>
        <img src={KusundaImg} className="kusunda-img" alt="kusunda" />
        <div className="aboutpage__tagline">
          <div className="aboutpage__info">
            <button onClick={() => this.showPopup("about")}>About</button>
          </div>
          <div className="aboutpage__info">
            <button onClick={() => this.showPopup("trailer")}>Trailer</button>
          </div>
          <div className="aboutpage__info">
            <button onClick={() => this.showPopup("screenings")}>
              Screenings
            </button>
          </div>
          <div className="aboutpage__info">
            <button onClick={() => this.showPopup("makingOf")}>
              The Making Of
            </button>
          </div>
          <div className="aboutpage__info">
            <button onClick={() => this.showPopup("press")}>
              Press & Awards
            </button>
          </div>
        </div>
        <div className="aboutpage__imprint">
          <div className="aboutpage__imprint-nowhere-logo">
            <a href="https://www.nowheremedia.net/" target="_blank">
              <img
                src={nowHereLogo}
                className="aboutpage__logo-img"
                alt="logo"
              />
            </a>
          </div>
        </div>

        {this.state.show === "about" ? (
          <About closePopup={this.closePopup.bind(this)} episode="kusunda" />
        ) : this.state.show === "trailer" ? (
          <VideoPopup
            source="https://player.vimeo.com/video/540175978?h=d572543590"
            closePopup={this.closePopup.bind(this)}
          />
        ) : this.state.show === "screenings" ? (
          <Screenings
            screenings={kusundaScreenings}
            closePopup={this.closePopup.bind(this)}
          />
        ) : this.state.show === "makingOf" ? (
          <VideoPopup
            source="https://player.vimeo.com/video/592064998?h=859ad7b778"
            closePopup={this.closePopup.bind(this)}
          />
        ) : this.state.show === "press" ? (
          <Press
            pressQuotes={kusundaPressQuotes}
            awards={kusundaAwards}
            closePopup={this.closePopup.bind(this)}
          />
        ) : null}
      </div>
    );
  }
}

export default KusundaMain;
